.contact{
    position: relative;
    width: 100%;
    height: 100vh;
}

.contact .bgimg{
    width: 100%;
    height: 100%;
    background-image: url(../../assets/about.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

.contact .image-wrapper{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.contact .content{
    color: #f1f1f1;
    overflow: hidden;
}

.contact .content .sub-content{
  display: flex;
  margin-top: 10px;
}

.contact .content .sub-content span, .contact .content .sub-content .form-content{
  flex: 1;
}

.contact .content .sub-content span{
  text-align: right;
  padding-right: 20px;
}

.contact .content .sub-content .form-content{
  text-align: left;
  padding-left: 20px;
} 
.contact .form{
  width: 60%;
}

.contact .form .form-control{
  background-color: transparent !important;
  color: white;
  border: 1px solid white
}
.contact .form .form-control:after {
  content:" *";
  color: red;
}


.contact .content h1{
  font-family: "Oswald", Helvetica, sans-serif;
    font-weight: 700;
    font-size: 5rem;
    text-transform: uppercase;
    margin-bottom: 20px;
      
  } 

  .contact .content h5{
      margin-bottom: 20px;
      font-size: 1rem;
  }

  .contact .content .icon{
      cursor: pointer;
      margin:5px;
  }

  .contact .content a{
      color: white;
      text-decoration: none;
  }

  .contact .content a:hover{
    color: lightgray;
}

  .contact .btn-outline-warning{
    color: #cc9a4f;
    background-color: transparent;
    background-image: none;
    border-color: #cc9a4f;
}

.contact .btn-outline-warning:hover {
    color: #212529;
    background-color: #cc9a4f;
    border-color: #cc9a4f;
}

.contact .send{
  font-weight: bold;
  color: #cc9a4f;
  border: white 1px solid;
  background-color: hsla(0, 0%, 0%, 0);
  font-size: 1rem;
  border-radius: 5px;
  margin-top: 20px !important;
  float: right;
}

.contact .send:hover{
  color: white;
  /* background-color: #cc9a4f; */
}
.contact .map-btn{
  font-family: "Oswald", Helvetica, sans-serif;
  letter-spacing: 2px;
  font-size: 15px;
  background-color: transparent;
  color: white;
  border: 1px solid rgba(255,255,255,0.5);
  padding: 10px 20px;
  margin-top: 30px;
}
.contact .map-btn:hover{
  background-color: rgba(211, 211, 211, 0.205);
}

#map-modal{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: rgba(14, 14, 15, 0.95);
  display: none;
}
.contact .gm-style, .contact .gm-style h5, .contact .gm-style, .contact .gm-style p{
  color: black;
}
.contact .gm-style, .contact .gm-style a{
  color: blue;
}
.contact .gm-style a:hover{
  color: blue;
}
.contact .gm-style .labels{
  font-weight: 700;
  font-size: 50px;
}

@media(max-width: 767px){
  .contact .content .sub-content{
    flex-direction: column;
  }
  .contact .content .sub-content .form-content{
    text-align: lcentereft;
  } 
  
  .contact .form{
    margin: auto !important;
    width: 50%;
  }

  .contact .content .sub-content span{
    text-align: center;
  }
  }

  @media(max-width: 1000px) and (orientation: landscape){
    .contact{
      height: 150vh;
  }
    .contact .content .sub-content{
      flex-direction: row;
    }
    
    .contact .form{
      margin: auto !important;
      width: 80%;
    }
    .contact .content h1{
      font-size: 3rem;
      margin-bottom: 20px;
    }
    .contact .content h5{
      font-size: 0.8rem;
      margin-bottom: 20px;
    }
    .contact .content p{
      font-size: 12px;
    }
  }


  @media(max-width: 576px){
    .contact{
      height: 150vh;
    }
  .contact .form{
    margin: auto !important;
    width: 80%;
  }
  .contact .content h1{
    font-size: 3rem;
    margin-bottom: 20px;
  }
  .contact .content h5{
    font-size: 0.8rem;
    margin-bottom: 20px;
  }
  .contact .content p{
    font-size: 12px;
  }
  }