@media(min-width:767px){
    .reviews-video-section{
        height: calc(100vh - 55px);
        margin-left: 25px;
        margin-right: 25px;
    }
}

.reviews-video-section {
    position: relative;
    /* width: 100%; */
    height: calc(100vh - 30px);
    overflow: hidden;
    margin: 0 !important;
}

.reviews-video-section .social-share{
    position: absolute;
    z-index: 101;
    top: 20px;
    right: 20px;
  }
  .reviews-video-section .ctc-icon{
      font-size: 30px;
      cursor: pointer;
      position: relative;
      top: 50%;
      transform: translateY(14%);
      padding: 10px;
      background-color: grey;
  }


.reviews-video-section .video-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    /* background-color: black; */
    background-image: url(../../../assets/testimonials/cover_img.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .reviews-video-section .video-wrapper.hide{
      display: none;
  }

  .reviews-video-section .video-wrapper .playpause{
    position: relative;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    /* height: 100%;
    width: 100%; */
    font-size: 120px;
    color: #cc9a4f;
  }

.reviews-video-section .controls{
    margin-bottom: 25px;
    padding-bottom: 25px;
}

.reviews-video-section .video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center;
}
.reviews-video-section .rvs-slide.video{
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center;
    display: none;
    transition: opacity 0.4s ease-in-out;
}

.reviews-video-section .rvs-slide.current.video{
    display: block;
}

.reviews-video-section #rvs-next{
    position: absolute;
    top: 50%;
    right: 25px;
    z-index: 500;
}
.reviews-video-section #rvs-prev{
    position: absolute;
    top: 50%;
    left: 25px;
    z-index: 500;
}

.reviews-video-section button{
    /* width: 50px;
    height: 50px; */
    font-weight: 700;
    border: 3px solid #cc9a4f;
    background-color: rgba(0,0,0,0.5);
    color: #cc9a4f;
    cursor: pointer;
    padding: 13px 15px;
    border-radius: 5%;
    outline: none;
}

.reviews-video-section button:hover{
    background-color: #cc9a4f;
    color: #fff;
}