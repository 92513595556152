.join-modal .btn-outline-warning{
    color: #cc9a4f;
    background-color: transparent;
    background-image: none;
    border-color: #cc9a4f;
}

.join-modal .btn-outline-warning:hover {
    color: #212529;
    background-color: #cc9a4f;
    border-color: #cc9a4f;
}

.join-modal .send{
    font-weight: bold;
    color: #cc9a4f;
    border: #cc9a4f 1px solid;
    background-color: hsla(0, 0%, 0%, 0);
    font-size: 1rem;
    border-radius: 5px;
    margin-top: 20px !important;
  }
  
  .join-modal .send:hover{
    color: black;
    background-color: #cc9a4f;
  }