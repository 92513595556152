
  
  .accessories{
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  .accessories .bgimg{
    width: 100%;
    height: 100%;
    background-image: url(../../../assets/services/accessories_bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}
  
.accessories .wrapper {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 40px;
  padding-bottom: 55px;
}

  
  .accessories .content{
    color: #f1f1f1;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .accessories .content p,.accessories .content h1{
    flex: 1;
    text-align: center;
    padding: 0 5px;
  }

  .accessories .content h1{
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    font-size: 3rem;
    text-transform: uppercase;
    background-image: -webkit-linear-gradient( rgba(204, 154, 79, 0.66) 50%, white 50%);
    background-repeat: repeat;
    background-position: 0 0;
    background-size: 100% 50px;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  
    -webkit-animation: stripes 2s linear infinite;
    animation: stripes 2s linear infinite;
      
  }
  @-webkit-keyframes stripes {
    100% {
      background-position: 20px 50px;
    }
  }
   
  @keyframes stripes {
    100% {
      background-position: 20px -50px;
    }
  }

  #accessories .loggedin-accessories{
    position: relative;
    width: 100%;
    height: 100vh;
}
#accessories .loggedin-accessories .accessories-banner-bgimg{
  width: 100%;
  height: 100vh;
  background-image: url(../../../assets/services/accessories_banner_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

#accessories .loggedin-accessories .content{
    color: #f1f1f1;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
    text-align: center;
}
#accessories .loggedin-accessories .wrapper{
  font-family: "Oswald", Helvetica, sans-serif;
  letter-spacing: 1px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#accessories .loggedin-accessories .wrapper .top-row{
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 100%;
  height: 20%;
  margin: 0;
  padding-top: 50px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
#accessories .loggedin-accessories .wrapper .top-row h5{
  font-size: 30px;
}
#accessories .loggedin-accessories .wrapper .sub-content{
  /* position: absolute;
  left: 0;
  bottom: 0; */
  width: 100%;
  height: 30%;
  padding-bottom: 30px;
  margin: 0;
}
#accessories .loggedin-accessories .wrapper .sub-content .col{
  /* width: 100%;
  height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}
#accessories .loggedin-accessories .wrapper .sub-content .btn{
  font-weight: bold;
  color: #f1f1f1;
  border: #f1f1f1 1px solid;
  background-color: hsla(0, 0%, 0%, 0);
  font-size: 1.2rem;
  letter-spacing: 1px;
  border-radius: 5px;
  /* margin-top: 20px !important; */
  /* padding: 20px; */
  height: 70%;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#accessories .loggedin-accessories .wrapper .sub-content .btn:hover{
  color: black;
  background-color: white;
}
  

  @media(max-width: 1000px) and (orientation: landscape){
    .accessories .wrapper{
      padding-bottom: 30px;
    }
    .accessories .content h1{
      font-size: 25px;
    }

    .accessories .content p{
      font-size: 12px;
    }
  }

  @media(max-width: 991px){
    .accessories .content{
      flex-direction: column;
    }
    #accessories .loggedin-accessories .wrapper .sub-content{
      height: 70%;
    }
  }

  @media(max-width: 767px){
    .accessories .content h1{
      font-size: 35px;
    }

    .accessories .content p{
      font-size: 15px;
    }
  }

  @media(max-width: 576px){
    .accessories .content h1{
      font-size: 25px;
    }

    .accessories .content p{
      font-size: 12px;
    }
    #accessories .loggedin-accessories .wrapper .top-row h5{
      font-size: 20px;
    }
  }
