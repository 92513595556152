.all-testimonials {
    position: relative;
    overflow: hidden;
    top: 90px;
    height: calc(100vh - 90px);
}

.all-testimonials .content{
    position: relative;
    height: 100%;
    margin: 0 !important;
}
.all-testimonials .top-controls{
    background-color: rgba(204, 154, 79, 1);
    height: 90px;
}

.all-testimonials .top-controls .cross{
    float: right;
    /* line-height: 90px; */
    font-size: 40px;
    margin: 25px;
    color: white;
}
.all-testimonials .top-controls .cross:hover{
    cursor: pointer;
}

.all-testimonials .video-wrapper{
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    /* background-color: black; */
    background-image: url(../../../assets/testimonials/cover_img.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .all-testimonials .video-wrapper.hide{
      display: none;
  }

  .all-testimonials .video-wrapper .playpause{
    position: relative;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    /* height: 100%;
    width: 100%; */
    font-size: 120px;
    color: #cc9a4f;
  }

.all-testimonials .controls{
    margin-bottom: 25px;
    padding-bottom: 25px;
}

.all-testimonials video.slide{
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center;
    display: none;
    transition: opacity 0.4s ease-in-out;
}

.all-testimonials .slide.current.video{
    display: block;
}

.all-testimonials #next{
    position: absolute;
    top: 50%;
    right: 25px;
    z-index: 500;
}
.all-testimonials #prev{
    position: absolute;
    top: 50%;
    left: 25px;
    z-index: 500;
}

.all-testimonials button{
    /* width: 50px;
    height: 50px; */
    font-weight: 700;
    border: 3px solid #cc9a4f;
    background-color: rgba(0,0,0,0.5);
    color: #cc9a4f;
    cursor: pointer;
    padding: 13px 15px;
    border-radius: 5%;
    outline: none;
}

.all-testimonials button:hover{
    background-color: #cc9a4f;
    color: #fff;
}

.all-testimonials .social-share{
    position: absolute;
    z-index: 101;
    top: 20px;
    right: 20px;
  }
  .all-testimonials .ctc-icon{
      font-size: 30px;
      cursor: pointer;
      position: relative;
      top: 50%;
      transform: translateY(14%);
      padding: 10px;
      background-color: grey;
  }
@media(min-width:767px){
    .all-testimonials{
        /* height: calc(100vh - 55px);
        margin-left: 25px;
        margin-right: 25px; */
    }
}