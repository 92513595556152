.gallery-modal .gm-contorls{
    background-color: rgba(204, 154, 79, 1);
    height: 90px;
}

.gallery-modal .gm-contorls .cross{
    float: right;
    /* line-height: 90px; */
    font-size: 40px;
    margin: 25px;
    color: white;
}
.gallery-modal .gm-contorls .cross:hover{
    cursor: pointer;
}

.gallery-modal .gm-content{
    width: 100%;
    padding: 20px 40px;
    /* overflow-y: scroll; */
}

.gallery-modal .gm-content h1{
    font-family: "Oswald", Helvetica, sans-serif;
    font-size: 60px;
    font-weight: 700;
    line-height: 90px;
    letter-spacing: 20px;
    text-transform: uppercase;
    color: white;
    text-align: center;
}
.gallery-modal .gm-content h1 span{
    color: #cc9a4f;
}

.gallery-modal .gm-content .video{
    width: 100% !important;
    height: 60vh !important;
    padding: 0 15px;
}

.gallery-modal .gm-content h5{ 
    font-family: "Oswald", Helvetica, sans-serif;  
    font-size: 25px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 5px;
    color: white;
}

.gallery-modal .gm-content p{
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 1.2px;
    margin: 0 0 11px 0;
    color: white;
}

.gallery-modal .sec-col{
    display:flex;
    justify-content:center;
    align-items:center;
    text-align: center;
}

.gallery-modal .checked{
    color: orange;
}


.gallery-modal .ctc-icon{
    font-size: 25px;
    cursor: pointer;
    position: relative;
    top: 50%;
    transform: translateY(14%);
    padding: 5px;
    background-color: grey;
    color: white;
}

@media(max-width: 991px){
    .gallery-modal .sec-col{
        margin-bottom: 20px;
    }
    .gallery-modal .gm-content h1{
        letter-spacing: 0px;
        line-height: 70px;
        margin-bottom: 20px;
    }
	.gallery-modal .first-col {
		order: 2;
	}
	.gallery-modal .sec-col {
		order: 1;
    }
    .gallery-modal .gm-content .video{
        height: 40vh !important;
    }
}