.header{
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}
.header .video {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
}

/* .header .bgimg{
    width: 100%;
    min-height: 100vh;
    background-image: url(../../../assets/header.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
} */

.header .wrapper {
    position: absolute;
    bottom: 0;
    /* background: rgba(0, 0, 0, 0.5); */
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
  }

  .header .content{
      position: absolute;
      bottom: 0;
      z-index: 100;
      color: #f1f1f1;
      font-family: "Oswald", Helvetica, sans-serif;
      text-transform: uppercase;
      width: 100%;
      min-height: 100vh;
      overflow: hidden;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

  .header .content h2{
      font-size: 50px;
      letter-spacing: 20px;
      font-weight: 300;
  }
  .header .content h1{
    font-size: 80px;
    letter-spacing: 10px;
    font-weight: 300;
    padding: 0 30px;
}
.header .content p{
    letter-spacing: 1px;
}

.header .content h1 span, .header .content h2 span{
    color: rgba(204, 154, 79, 0.9);
    /* color: #577399; */
}

  .header .content .btn-group button {
    color: white; /* White text */
    padding: 10px 24px; /* Some padding */
    cursor: pointer; /* Pointer/hand icon */
    float: left; /* Float the buttons side by side */
    border: none;
    margin: 5px;
    letter-spacing: 1px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
  .header .content .btn-group .btn-au{
      background-color: rgba(51, 51, 51, 0.9);
  }
  
  .header .content .btn-group .btn-ow{
    background-color: rgba(204, 154, 79, 0.9);
    /* background-color: #577399; */
}
.header .content .btn-group .btn-au:hover{
    background-color: rgba(75, 75, 75, 0.9);
}

.header .content .btn-group .btn-ow:hover{
    background-color: rgba(236, 179, 92, 0.9);
}
  
  .header .content .btn-group button:not(:last-child) {
    border-right: none; /* Prevent double borders */
  }
  
  /* Clear floats (clearfix hack) */
  .header .content .btn-group:after {
    content: "";
    clear: both;
    display: table;
  }
  .header .ham-menu{
     position: fixed;
     z-index: 1000;
     padding-top: 40px;
     padding-bottom: 25px;
     padding-left: 25px;
     padding-right: 25px;
     width: 100%;
     height: 100%;
     overflow: hidden;
     background-color: #111111;
     display: none;
 }
 .header .mobile-ham-menu{
   position: fixed;
   z-index: 1000;
   padding-top: 40px;
   /* padding-bottom: 25px; */
   /* padding-left: 25px;
   padding-right: 25px; */
   width: 100%;
   height: 100%;
   overflow: hidden;
   background-color: #111111;
   display: none;
 }
 .header .icon-wrap{
   position: fixed;
   bottom: 40px;
   right: 40px;
   /* width: 60px;
   height: 60px; */
   background-color: rgba(75, 75, 75, 1);
   color: white;
   border-radius: 25px;
   padding: 10px 15px;
   box-shadow: 0px 0px 10px 5px #888888;
   z-index: 1000;
   display: flex;
   justify-content: center;
   align-items: center;
   transition: ease-in-out 0.5s;
 }
 .header .icon-wrap:hover{
   cursor: pointer;
   transform: scale(1.1);
 }
 .header .icon-wrap h2{
  font-size: 18px;
  letter-spacing: 1.5px;
  margin: 0;
 }
 .header .icon-wrap h2 span{
   color: rgba(204, 154, 79, 1);
 }
 .header .store-icon{
   font-size: 25px;
   transition: ease-in-out 0.5s;
   color: rgba(236, 179, 92, 1);
   margin-right: 5px;
 }

 .header .store-popup{
   position: fixed;
   bottom: 110px;
   right: 40px;
   font-size: 15px;
   font-weight: 500;
   z-index: 1000;
   letter-spacing: 1px;
   background-color: rgba(236, 179, 92, 1);
   border-radius: 25px;
   padding: 2px 10px;
   transition: ease-in-out 0.5s;
   display: none;
 }
 .header .icon-wrap:hover + .store-popup{
   display: block;
 }

  @media (max-width: 480px){
      .header .content h1{
          font-size: 50px;
          letter-spacing: normal;
      }
      .header .content h2{
        font-size: 25px;
        letter-spacing: normal;
    }
    .header .content p{
        font-size: 12px;
    }
    .header .content .btn-group button{
        font-size: 15px;
    }
    .header .icon-wrap{
      bottom: 20px;
      right: 20px;
      padding: 5px 10px;
    }
    .header .icon-wrap h2{
      font-size: 18px;
      letter-spacing: normal;
      margin: 0;
     }
    .header .store-popup{
      bottom: 90px;
      right: 20px;
    }
  }