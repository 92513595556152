.header-navbar{
    position: fixed;
    transition: line-height 0.5s, top 0.5s, right 0.5s, left 0.5s, bottom 0.5s;
    font-family: "Oswald", Helvetica, sans-serif;
    text-transform: uppercase;
    z-index: 1030;
    padding: 0 50px;
    height: 40px !important;
    background: rgba(0, 0, 0, 0.8);
    color: white;

  }

  .state1 .header-navbar .active{
    color: #cc9a4f;
    /* color: black; */
  }

  .state2 .header-navbar .active{
    /* color: black; */
    color: #cc9a4f;
  }

  .state1 .header-navbar .active .top.dropdown-toggle::after{
    color: #cc9a4f;
    /* color: black; */
  }
  .state2 .header-navbar .active .top.dropdown-toggle::after{
    /* color: black; */
    color: #cc9a4f;
  }

  .state1 .header-navbar{
      /* top: 40px; */
      top: 0;
      line-height: 40px !important;
  }

  .state2 .header-navbar{
    top:0;
    line-height: 40px !important;

  }

  

  .header-navbar .logo{
    height: 40px;
    padding: 0;
  }

.header-navbar .date, .login,.header-navbar .nav-item{
    padding: 0;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 1px;
}
.header-navbar .nav-link {
  padding: 0 !important;
  /* color: white !important; */
}

.header-navbar a{
  color: inherit;
}

.header-navbar .navbar-nav .dropdown-menu{
  padding: 0 !important;
  border: 0;
  border-radius: 0;
}
.header-navbar .navbar-nav .dropdown-menu .dropdown-item{
  font-size: 15px;
  font-weight: 700;
  /* height: 50px; */
  padding: 0 11px;
}
.header-navbar .navbar-nav .top.dropdown-menu .dropdown-item:hover, 
.header-navbar .navbar-nav .top.dropdown-menu .dropdown-item:focus{
  background-color: #cc9a4f;
  /* background-color: black; */
  color: white;
  border: 0;
  outline: none;
  cursor: pointer;
}

.header-navbar .nav-item{
  position: relative;
  cursor: pointer;
  margin: 0;
}
.header-navbar .navbar-brand, .header-navbar .top.dropdown{
  padding: 0 11px;
}

.header-navbar .login{
  padding: 0 11px;
  margin: 0;
}

.header-navbar .logo{
  font-weight: 800;
  font-size: 20px;
}

.header-navbar .logo span{
    color: #cc9a4f;
    /* color: black; */
}
  
  .state2 .header-navbar .logo span{
      /* color: black; */
      color: #cc9a4f;
  }

.header-navbar .toggle{
  position: relative;
  border-color: transparent !important;
  margin-left: auto;
  width: 40px;
  height: 40px;
  border: none;
  padding: 0;
}

.header-navbar .toggle:focus{
  outline: 0 !important;
}

.header-navbar .login:hover{
  cursor: pointer;
}

.header-navbar .dropdown-menu {
  margin-top: 0;
}

.header-navbar .toggle-icon{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: white;
}
.state1 .header-navbar .active:hover {
  color: #cc9a4f;
  /* color: black; */
  text-decoration: none;
}
.state2 .header-navbar .active:hover {
  /* color: black; */
  color: #cc9a4f;
  text-decoration: none;
}

.header-navbar .nav-item:focus{
  outline: none;
}

.header-navbar .logo h3{
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
  margin-bottom: 0px;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  /* color: black; */
  color: white;
  /* background-color: rgba(250, 248, 248, 0.5); */
    background: rgba(0, 0, 0, 0.8);
  margin-top: 5px;
}
.state1 .header-navbar #user-modal{
  position: fixed;
  z-index: 1000;
  top: 90px;
  right: 50px;
  border-radius: 5px;
  /* height: 0; */
  transition: .5s ease;
  overflow: hidden;
  background-color: white;
  display: none;
}
.state2 .header-navbar #user-modal{
  position: fixed;
  z-index: 1000;
  top: 50px;
  right: 35px;
  border-radius: 5px;
  /* height: 0; */
  transition: .5s ease;
  overflow: hidden;
  background-color: white;
  display: none;
}
.header-navbar #user-modal.visible{
  display: block;
}

@media(max-width:1300px){
  .header-navbar .date, .login, .header-navbar .nav-item{
    font-size: 12px;
}
.header-navbar .navbar-nav .dropdown-menu .dropdown-item{
  font-size: 12px;
}
}

@media(min-width: 1200px){
.header-navbar .navbar-brand{
  padding: 0 5px;
}
.header-navbar .nav-item{
  padding: 0 5px !important;
}

.header-navbar .date{
  padding: 0 11px !important;
}

  .header-navbar .nav-item span:before {
    content: "[";
    margin-right: 6px;
    display: inline-block;
    opacity: 0;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    transform: translateX(20px);
    -webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
    -moz-transition: -moz-transform 0.3s, opacity 0.2s;
    transition: transform 0.3s, opacity 0.2s;
  }
  
  .header-navbar .nav-item span:after {
    content: "]";
    margin-left: 6px;
    display: inline-block;
    opacity: 0;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
    -moz-transition: -moz-transform 0.3s, opacity 0.2s;
    transition: transform 0.3s, opacity 0.2s;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  
  .header-navbar .nav-item span:hover:before,
  .header-navbar .nav-item span:hover:after {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    transform: translateX(0px);
  }

  .header-navbar .navbar-nav .bottom.dropdown-menu .dropdown-item{
   width: 200px;
   background-color: white;
  }
  .state1 .header-navbar .bottom.dropdown-menu{
    left: 148px;
    top: -2.5px;
  }
.state2 .header-navbar .bottom.dropdown-menu{
  left: 148px;
  top: -5px;
}

  .header-navbar .navbar-nav .dropdown-menu .dropdown-item{
    height: 50px;
  }
  .header-navbar .nav, .header-navbar .navbar-nav{
    height: 40px;
  }
}
@media(min-width: 1300px){

  .header-navbar .navbar-nav .bottom.dropdown-menu .dropdown-item{
   width: 250px;
  }
}

@media(max-width: 1199px){
  .header-navbar .navbar-nav{
    display: none;
  }
  .header-navbar .list{
      transition: 500ms ease;
      background-color: rgba(0,0,0, 0.8);
      overflow-y: scroll;
      overflow-x: hidden;
      max-height: 220px;
  }
  /* .header-navbar .list{
    transition: 500ms ease;
    background-color: black;
    overflow-y: scroll;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
}
.header-navbar .list .navbar-nav{
  width: 100%;
  height: 100%;
}
.header-navbar .list .nav-item{
  width: 100%;
  height: 50%;
} */
  .header-navbar .list::-webkit-scrollbar-track {
    border: 1px solid #000;
    padding: 2px 0;
    background-color: #404040;
  }
  
  .header-navbar .list::-webkit-scrollbar {
    width: 10px;
  }
  
  .header-navbar .list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #cc9a4f;
    /* background-color: black; */
    border: 1px solid #000;
  }

  
  .header-navbar .login, .header-navbar .date, .header-navbar .nav-item{
      margin: 0;
  }
  
  .state2 .header-navbar .active{
    /* color: black; */
    color: #cc9a4f;
  }
  .state2 .header-navbar .nav-item:hover .active,.state2 .header-navbar .nav-item:hover .top.dropdown-toggle::after{
    color: white;
  }

.header-navbar .nav-item:hover{
      background-color: #cc9a4f;
      /* background-color: black; */
      color: white !important;
  }

  .state2 .header-navbar .active .top.dropdown-toggle::after{
    color: #cc9a4f;
    /* color: black; */
  }
}



@media(max-width: 777px){
  .state1 .header-navbar{
      top:0;
      line-height: 40px !important;
  
    }

  .header-navbar .logo{
      color: white;
    }
    
  .header-navbar .logo span{
        /* color: black; */
        /* color: #cc9a4f; */
    }
}

@media(max-width:767px){
  
.state1 .header-navbar #user-modal{
  top: 50px;
  right: 10px;
}
.state2 .header-navbar #user-modal{
  top: 50px;
  right: 10px;
}
}

@media(max-width: 443px){

  .header-navbar{
    padding: 0 10px;
  }
  .header-navbar .logo{
    font-weight: 800;
    font-size: 15px;
    margin-right: 10px;
  }
}
