.introduction-section{
    position: relative;
    width: 100%;
    height: 100vh;
}

.introduction-section .content{
    color: #f1f1f1;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
    text-align: center;
}

/* .introduction-section .sub-content{
    width: 100%;
    height: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
    text-align: center;
} */

.introduction-section .accessories-banner-bgimg{
  width: 100%;
  height: 100vh;
  background-image: url(../../assets/services/accessories_banner_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.repair-banner-bgimg{
  width: 100%;
  height: 100vh;
  background-image: url(../../assets/services/repair_banner.jpg);
  background-repeat: no-repeat;
  /* -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%; */
  background-size: contain;
  background-attachment: fixed;
  background-position: center;
  background-color: #e6cf42;
}

.activation-banner-bgimg{
  width: 100%;
  height: 100vh;
  background-image: url(../../assets/services/activation_banner.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
  background-position: center;
  background-color: black;
}

/* .introduction-section .wrapper{
  font-family: "Oswald", Helvetica, sans-serif;
  letter-spacing: 1px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} */


/* .introduction-section .sub-content{
    color: #f1f1f1;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
} */


/* .introduction-section .content h1{
    font: 700 4em/1 "Oswald", sans-serif;
    text-align: center;
    letter-spacing: 0;
    padding: .25em 0 .325em;
    display: block;
    margin: 0 auto;
    text-shadow: 0 0 80px rgba(255,255,255,.5);


    
  background-image: -webkit-linear-gradient( rgba(204, 154, 79, 0.66) 50%, white 50%);
  background-repeat: repeat;
  background-position: 0 0;
  background-size: 100% 50px;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
 
  -webkit-animation: stripes 2s linear infinite;
  animation: stripes 2s linear infinite;

    
    
  
}

  @-webkit-keyframes stripes {
    100% {
      background-position: 20px 50px;
    }
  }
   
  @keyframes stripes {
    100% {
      background-position: 20px -50px;
    }
  } */

  @media(max-width: 1200px){
    /* .repair-banner-bgimg{
      background-image: url(../../assets/services/mobile_repair_banner.jpg);
    } */
  }

  @media(max-width:991px){
    
.introduction-section .wrapper .sub-content{
  height: 70%;
}
    
/* .introduction-section .wrapper .sub-content .col{
  height: auto;
} */
/* .introduction-section .wrapper .sub-content .btn{
  height: 60%;
} */
  }

  @media(max-width: 576px){
    /* .introduction-section .sub-content h1{
        font-size: 3rem;
    } */
    .introduction-section .wrapper .top-row h5{
      font-size: 20px;
    }
}