.store-locator{
    padding: 0 25px 0 25px !important;
}

#map{
    height: calc(100vh - 90px);
    width: 100%;
    margin: 0 !important;
}

@media(max-width: 767px){
    .store-locator{
        padding: 0 !important;
    }
    /* #map{
        height: calc(100vh - 35px);
    } */
}