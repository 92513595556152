
  
  .activation{
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .activation .bgimg{
    width: 100%;
    height: 100vh;
    background-image: url(../../../assets/services/activation.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}
  
.activation .wrapper {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  color: #f1f1f1;
  width: 100%;
  padding: 40px;
  padding-bottom: 70px;
}
  
  .activation .content{
    color: #f1f1f1;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .activation .content p,.activation .content h1{
    flex: 1;
    text-align: center;
    padding: 0 5px;
  }
  .activation .content h1{
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    font-size: 3rem;
    text-transform: uppercase;
    background-image: -webkit-linear-gradient( rgba(204, 154, 79, 0.66) 50%, white 50%);
    background-repeat: repeat;
    background-position: 0 0;
    background-size: 100% 50px;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  
    -webkit-animation: stripes 2s linear infinite;
    animation: stripes 2s linear infinite;
      
  }
  @-webkit-keyframes stripes {
    100% {
      background-position: 20px 50px;
    }
  }
   
  @keyframes stripes {
    100% {
      background-position: 20px -50px;
    }
  }
  

  @media(max-width: 1000px) and (orientation: landscape){
    .activation .wrapper{
      padding-bottom: 30px;
    }
    .activation .content h1{
      font-size: 25px;
    }

    .activation .content p{
      font-size: 12px;
    }
  }

  @media(max-width: 992px){
    .activation .content{
      flex-direction: column;
    }
  }

  @media(max-width: 767px){
    .activation .content h1{
      font-size: 35px;
    }

    .activation .content p{
      font-size: 15px;
    }
  }

  @media(max-width: 576px){
    .activation .content h1{
      font-size: 25px;
    }

    .activation .content p{
      font-size: 12px;
    }
  }