.be-the-boss{
    position: relative;
    width: 100%;
    height: 100vh;
}

.be-the-boss .bgimg{
    width: 100%;
    height: 100vh;
    background-image: url(../../../assets/join.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

.be-the-boss .image-wrapper{
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.be-the-boss .content{
    color: #f1f1f1;
    overflow: hidden;
    padding: 40px;
}

.be-the-boss .content h1{
  font-family: "Oswald", Helvetica, sans-serif;
    font-weight: 700;
    font-size: 5rem;
    text-transform: uppercase;
    margin-bottom: 50px;
    background-image: -webkit-linear-gradient( rgba(204, 154, 79, 0.66) 50%, white 50%);
    background-repeat: repeat;
    background-position: 0 0;
    background-size: 100% 50px;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  
    -webkit-animation: stripes 2s linear infinite;
    animation: stripes 2s linear infinite;
      
  }
  @-webkit-keyframes stripes {
    100% {
      background-position: 20px 50px;
    }
  }
   
  @keyframes stripes {
    100% {
      background-position: 20px -50px;
    }
  }

  .be-the-boss .btn{
    font-weight: bold;
    color: #f1f1f1;
    border: #f1f1f1 1px solid;
    background-color: hsla(0, 0%, 0%, 0);
    font-size: 1rem;
    border-radius: 5px;
    margin-top: 20px !important;
    padding: 10px;
    width: 30%;
  }

  .be-the-boss .btn:hover{
    color: black;
    background-color: white;
  }

  @media(min-width: 1300px){
    .be-the-boss .content{
        padding-right: 200px;
        padding-left: 200px;
    }
  }

  @media(max-width: 767px){
    .be-the-boss .content h1{
      font-size: 4rem;
      margin-bottom: 40px;
    }

    .be-the-boss .content p{
      font-size: 15px;
    }
  }

  @media(max-width: 1000px) and (orientation: landscape){
    .be-the-boss .content h1{
      font-size: 3rem;
      margin-bottom: 20px;
    }

    .be-the-boss .content p{
      font-size: 12px;
    }
  }

  @media(max-width: 576px){
    .be-the-boss .content h1{
      font-size: 3rem;
      margin-bottom: 20px;
    }

    .be-the-boss .content p{
      font-size: 12px;
    }
    .be-the-boss .btn{
      width: 90%;
    }
  }