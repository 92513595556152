.login-modal .modal-header .close{
    outline: none;
}

@media(max-width: 576px){
    .login-modal h4{
        font-size: 18px;
    }
    .login-modal input{
        font-size: 12px;
    }
}