.repair{
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .repair .playpause {
    position: relative;
    /* left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%); */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 120px;
    color: #cc9a4f;
}
  .repair .playpausemob {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    color: #cc9a4f;
    margin-bottom: 20px;
}

  
  .repair #repair-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center;
  }
  .repair .wrapper {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    color: #f1f1f1;
    width: 100%;
    padding: 40px;
    padding-bottom: 55px;
  }
  
  .repair .content{
    color: #f1f1f1;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .repair .content p,.repair .content h1{
    flex: 1;
    text-align: center;
    padding: 0 5px;
  }

  .repair .content h1{
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    font-size: 3rem;
    text-transform: uppercase;
    background-image: -webkit-linear-gradient( rgba(204, 154, 79, 0.66) 50%, white 50%);
    background-repeat: repeat;
    background-position: 0 0;
    background-size: 100% 50px;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  
    -webkit-animation: stripes 2s linear infinite;
    animation: stripes 2s linear infinite;
      
  }
  @-webkit-keyframes stripes {
    100% {
      background-position: 20px 50px;
    }
  }
   
  @keyframes stripes {
    100% {
      background-position: 20px -50px;
    }
  }

  
  @media(max-width: 1000px) and (orientation: landscape){
    .repair .wrapper{
      padding-bottom: 30px;
    }
    .repair .content h1{
      font-size: 25px;
    }

    .repair .content p{
      font-size: 12px;
    }
  }

  @media(max-width: 992px){
    .repair .content{
      flex-direction: column;
    }
  }

  @media(max-width: 767px){
    .repair .content h1{
      font-size: 35px;
    }

    .repair .content p{
      font-size: 15px;
    }
  }

  @media(max-width: 767px) and (orientation: landscape){
    .repair .content h1{
      font-size: 30px;
    }

    .repair .content p{
      font-size: 13px;
    }
  }

  @media(max-width: 576px){
    .repair .content h1{
      font-size: 25px;
    }

    .repair .content p{
      font-size: 12px;
    }
  }