.nav-hamburger{
    position: relative;
    width: 40px;
    height: 40px;
}

.nav-hamburger .icon{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: white;
    cursor: pointer;
    font-size: 1.25rem;
    margin: auto;
}