.reviews{
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
}

.reviews .bgimg{
    width: 100%;
    min-height: 100vh;
    background-image: url(../../assets/about.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

.reviews .wrapper {
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    color: white;
    padding: 0 0 80px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .reviews .content{
      padding: 120px 40px 0 40px;
      width: 100%;
  }
  .reviews .content .rev-h1{
    font-family: "Oswald", Helvetica, sans-serif;
    text-align: center;
    font-weight: 700;
    font-size: 80px;
    text-transform: uppercase;
    background-image: -webkit-linear-gradient( rgba(204, 154, 79, 0.66) 50%, white 50%);
    background-repeat: repeat;
    background-position: 0 0;
    background-size: 100% 50px;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  
    -webkit-animation: stripes 2s linear infinite;
    animation: stripes 2s linear infinite;
      
  }
  @-webkit-keyframes stripes {
    100% {
      background-position: 20px 50px;
    }
  }
   
  @keyframes stripes {
    100% {
      background-position: 20px -50px;
    }
  }

.reviews .content h2{
    text-align: center;
    font-size: 15px;
    letter-spacing: 1.5px;
    margin-bottom: 30px;
}
.reviews .content h2 span{
  color: rgba(204, 154, 79, 0.9);
}
.reviews .content p{
    text-align: center;
    font-size: 18px;
    margin-bottom: 50px;
}
.reviews .filter{
  /* height: 10vh; */
  line-height: 10vh;
  text-align: center;
  padding: 0;
  /* padding: 0 20px 0 20px; */
  margin: 0;
  list-style: none;
  color: white;
  text-transform: uppercase;
}
.reviews .filter .active{
  color: #cc9a4f;
}

.reviews .filter li{
  display: inline-block;
  float: none;
  position: relative;
  text-indent: 0;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 800;
  padding: 5px 15px;
  letter-spacing: 2px;
  cursor: pointer;
}

.reviews .filter li:before {
  content: "[";
  margin-right: 6px;
  display: inline-block;
  opacity: 0;
  -webkit-transform: translateX(20px);
  -moz-transform: translateX(20px);
  transform: translateX(20px);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
  -moz-transition: -moz-transform 0.3s, opacity 0.2s;
  transition: transform 0.3s, opacity 0.2s;
}

.reviews .filter li:after {
  content: "]";
  margin-left: 6px;
  display: inline-block;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
  -moz-transition: -moz-transform 0.3s, opacity 0.2s;
  transition: transform 0.3s, opacity 0.2s;
  -webkit-transform: translateX(-20px);
  -moz-transform: translateX(-20px);
  transform: translateX(-20px);
}

.reviews .filter .btn:hover li:before,
.reviews .filter .btn:hover li:after {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  transform: translateX(0px);
}
.reviews .btn{
  font-weight: bold;
  color: #f1f1f1;
  border: #f1f1f1 1px solid;
  background-color: hsla(0, 0%, 0%, 0);
  font-size: 1rem;
  border-radius: 5px;
  /* margin-top: 20px !important; */
  padding: 5px;
  margin: 0 10px;
  text-align: center;
}

.reviews .btn:hover{
  color: black;
  background-color: white;
}

.reviews .box{
    position: relative;
    width: 25%;
    height: auto;
    overflow: hidden;
    float: left;
    transition: 0.5s;
  }
  .reviews .box .img-box img{
    width: 100%;
  }
  
  .reviews .box .img-box{
  position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .reviews .box:hover .img-box{
  -ms-transform: rotate(-20deg); 
  transform: rotate(-20deg) scale(1.5);
  }
  .reviews .box:hover{
  cursor: pointer;
  }
  .reviews .sub-content{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,.8);
  color: black;
  opacity: 0;
  transition: 0.5s;
  padding: 5%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  }
  .reviews .box:hover .sub-content{
  opacity: 1;
  }
    /* .reviews .sub-content p{
      position: relative;
      text-transform: uppercase;
      font-size: 12px;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
  
    } */
    
.reviews .box .img-box .video-icon{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 80px;
  color: #cc9a4f;
}
    
.reviews .box:hover .content{
  opacity: 1;
  }

  .reviews .sub-content h6{
    font-family: "Oswald", Helvetica, sans-serif;
    font-size: 2vw;
    line-height: 24px;
    font-weight: 700;

    }
    .reviews .sub-content h6 span{
      color: #cc9a4f;
  
    }
    .reviews .sub-content p{
      text-transform: uppercase;
      font-size: 1vw;
      margin-bottom: 10px;
  
    }
    .reviews .sub-content .video-icon{
      font-size: 2vw;
  
    }
    .reviews .controls{
      background-color: rgba(204, 154, 79, 1);
      height: 90px;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000;
  }
  
  .reviews .controls .cross{
      float: right;
      /* line-height: 90px; */
      font-size: 40px;
      margin: 25px;
      color: white;
  }
  .reviews .controls .cross:hover{
      cursor: pointer;
  }
    #reviews-gallery-modal{
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1040;
      width: 100%;
      height: 100vh;
      overflow-y: scroll;
      overflow-x: hidden;
      background-color: rgba(14, 14, 15, 0.95);
      display: none;
    }
    @media(max-width: 768px){
        .reviews .box{
          width: 50%;
      }
      }
      @media(max-width: 576px){
        .reviews .box{
          width: 100%;
      }
      .reviews .content h1{
          font-size: 60px;
      }
      .reviews .content p{
          font-size: 18px;
      }
        .reviews .sub-content p{
          font-size: 8px;
      
        }
      }